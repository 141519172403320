export default [
  {
    title: 'home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'manage',
    resource: 'tutors',
  },
  {
    title: 'positions',
    route: 'positions',
    icon: 'AwardIcon',
    action: 'manage',
    resource: 'positions',
  },
  {
    title: 'levels',
    route: 'levels',
    icon: 'LayersIcon',
    action: 'manage',
    resource: 'levels',
  },
  {
    title: 'jobs',
    route: 'jobs',
    icon: 'ArchiveIcon',
    action: 'manage',
    resource: 'jobs',
  },
  {
    title: 'companies',
    route: 'companies',
    icon: 'CloudIcon',
    action: 'manage',
    resource: 'companies',
  },
  {
    title: 'talk_centers',
    route: 'talk-centers',
    icon: 'PhoneIncomingIcon',
    action: 'manage',
    resource: 'talkCenters',
  },
  {
    title: 'talk_operation_times',
    route: 'talk-operation-times',
    icon: 'ClockIcon',
    action: 'manage',
    resource: 'talkOperationTimes',
  },
  {
    title: 'user_management',
    icon: 'UsersIcon',
    children: [
      {
        title: 'admin',
        route: 'user-management.admin',
        action: 'manage',
        resource: 'userManagement',
      },
      {
        title: 'tutor',
        route: 'user-management.tutor',
        action: 'manage',
        resource: 'userManagement',
      },
      {
        title: 'company_admin',
        route: 'user-management.company-admin',
        action: 'manage',
        resource: 'userManagement',
      },
    ],
  },
  {
    title: 'series',
    route: 'series',
    icon: 'CalendarIcon',
    action: 'manage',
    resource: 'series',
  },
  {
    title: 'rotations',
    route: 'rotations',
    icon: 'ChromeIcon',
    action: 'manage',
    resource: 'rotations',
  },
  {
    title: 'products',
    route: 'products',
    icon: 'BookIcon',
    action: 'manage',
    resource: 'products',
  },
  {
    title: 'company_students',
    route: 'company.students',
    icon: 'FileIcon',
    action: 'manage',
    resource: 'students',
  },
  {
    title: 'students',
    route: 'students',
    icon: 'UserIcon',
    action: 'manage',
    resource: 'userManagement',
  },
  {
    title: 'talk',
    icon: 'PhoneOutgoingIcon',
    children: [
      {
        title: 'today',
        route: 'talk.today.schedule',
        action: 'manage',
        resource: 'userManagement',
      },
      {
        title: 'week',
        route: 'talk.week.schedule',
        action: 'manage',
        resource: 'userManagement',
      },
    ],
  },
  {
    title: 'my_schedules',
    route: 'my.schedules',
    icon: 'FileIcon',
    action: 'can',
    resource: 'teach',
  },
  {
    title: 'post_lessons',
    icon: 'FileIcon',
    children: [
      {
        title: 'writing',
        route: 'post_lessons.writing',
        action: 'can',
        resource: 'teach',
      },
    ],
  },
]
